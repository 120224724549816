import React from 'react';
import PropTypes from 'prop-types';
import Backspace from '@material-ui/icons/Backspace';
import { Link } from 'react-router-dom';

import { Button } from 'react-admin';

const CloseButton = ({
    basePath = '',
    label = 'ra.action.Close',
    record = {},
    variant = 'contained',
    icon = <Backspace style={{marginRight: '0.5em', fontSize:'24px'}} />,
    ...rest
}) => (
    <Button
        component={Link}
        to={rest.redirect?rest.redirect:basePath}
        label={label}
        variant={variant}
    >
        {icon}
    </Button>
);

CloseButton.propTypes = {
    basePath: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    icon: PropTypes.element,
};

export default CloseButton;
