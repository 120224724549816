import React, { Fragment } from 'react';
import { isAdmin, getInfoValue } from './../lib/globalData';
import { List, Create, Edit, Datagrid, SimpleForm, ReferenceInput, AutocompleteInput, 
         TextField, EditButton, Filter, TextInput, SelectInput, FormDataConsumer, 
         DateInput, ReferenceArrayInput, AutocompleteArrayInput, 
         Show, SimpleShowLayout, ShowButton, FileInput, FileField,
         BooleanInput, RichTextField, TopToolbar
       } from 'react-admin';
import CloseToolbar from './../lib/CloseToolbar';
import RichTextInput from 'ra-input-rich-text'
//import { makeStyles } from '@material-ui/core';
//import { Link } from 'react-router-dom';
//import KingBed from '@material-ui/icons/KingBed';
//import { stringify } from 'query-string';
import DeleteIcon from '@material-ui/icons/HighlightOff';

const ListFilter = (props) => (
<Filter {...props} >
  <TextInput source="q" label="Suchen" alwaysOn resettable />
  
  <ReferenceInput source="id_cave" label="Höhle" reference="cave"
    perPage={10000} sort={{ field: 'cave_name', order: 'ASC' }} >
    <AutocompleteInput optionText="cave_name" inputValueMatcher={() => null} resettable allowEmpty/>
  </ReferenceInput>
  <ReferenceInput source="id_owner" label="Autor" reference="user"
    perPage={1000} sort={{ field: 'vornamename', order: 'ASC' }} filter={{ tourOwner:1 }} >
    <SelectInput optionText="vornamename" inputValueMatcher={() => null} resettable allowEmpty/>
  </ReferenceInput>
  
  <ReferenceInput source="xuserfilter" label="Teilnehmer" reference="user"
    perPage={100} sort={{ field: 'tour_anz', order: 'DESC'}} filter={{ tourTn:1 }}  >
    <AutocompleteInput optionText="vornamenametouranz" inputValueMatcher={() => null} resettable allowEmpty/>
  </ReferenceInput>
  
  <ReferenceInput source="xactivityfilter" label="Aktivität" reference="activity"
    perPage={100} sort={{ field: 'activity_name', order: 'ASC' }} >
    <SelectInput optionText="activity_name" inputValueMatcher={() => null} resettable allowEmpty/>
  </ReferenceInput>
  <ReferenceInput source="id_region" label="Region" reference="region"
    perPage={100} sort={{ field: 'countryregion_name', order: 'ASC' }} >
    <SelectInput optionText="countryregion_name" inputValueMatcher={() => null} resettable allowEmpty/>
  </ReferenceInput>
  <ReferenceInput source="id_land" label="Land" reference="land"
    perPage={300} sort={{ field: 'land_name', order: 'ASC' }} >
    <SelectInput optionText="land_name" inputValueMatcher={() => null} resettable allowEmpty/>
  </ReferenceInput>
</Filter>
);

const CondEditButton = (props) => {
  const myid = getInfoValue('id');
  if (!isAdmin() && props.record.id_owner!==myid) return null;
  return (
    <EditButton label="" {...props} />
  )
}
      
const ListForm = (props) => {
  /*
    <TextField source="end_datum" sortable={true} label="End-Datum"/>
    <TextField source="xuserlist" sortable={false} label="Teilnehmer"/>
    comment_titel        
  */
  //const anzbem = props.record.bemerkung ? props.record.bemerkung : props.record.comment_titel;
  return (
    <List {...props} filters={<ListFilter />} title="Touren"  sort={{ field: 'start_datum', order: 'DESC' }} exporter={isAdmin() && props.exporter} bulkActionButtons={isAdmin() && props.bulkActionButtons}>
      <Datagrid style={{ width: 'auto' }} >
        <TextField source="start_datum" sortable={true} label="Datum"/>
        <TextField source="dauerh" sortable={false} label="Dauer" />
        <TextField source="cave_info" sortable={false} label="Höhle"/>
        <TextField source="anztn" sortable={false} label="Personen"/>
        <TextField source="xactivitylist" sortable={false} label="Aktivitäten"/>
        <TextField source="anzbem" sortable={false} label="Bemerkung"/> 
        <TextField source="anzdoc" sortable={false} label="Dok"/> 
        <TextField source="owner_name" sortable={false} label="Author"/>
        <TextField source="id_nita" sortable={false} label="Id"/>
        <ShowButton label="" className="rowbutton" />
        <CondEditButton />
      </Datagrid>
    </List>
  )
}

const RTextInputAnhang = (props) => {
  //const myid = getInfoValue('id');
  //if (!isAdmin() && props.record.id_owner!==myid) return null;
  return (
    <RichTextInput source={props.source} fullWidth 
      toolbar={[
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],

        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['image'],
        ['clean'],
      ]}/>
  )
}
      
const PreviewField = ({ source, record = {} }) => <div><div dangerouslySetInnerHTML={{__html: record.preview}} /></div>;
const ImgsField = ({ source, record = {} }) => { 
  //console.log(record.imgs);
  if (!record.imgs || !record.imgs.length) return null;
  return (
    <div style={{minHeight:"320px"}}>
      {record.imgs.map((url, i) => (
        <img src={url} width="320" alt="" key={i} />
      ))}
    </div>
  )
};


const TourShowActions = ({ basePath, data, resource }) => {
  const myid = getInfoValue('id');
  return (
    <TopToolbar>
      {isAdmin() || data.id_owner===myid ? <EditButton basePath={basePath} record={data} /> : null }
    </TopToolbar>
  )
};

/*
    <TextInput source="subject" label="Betreff" fullWidth />
    <RTextInputAnhang />
    <PreviewField source="preview"/>
    <FileInput source="files" label="AnhÃ¤nge" multiple>
      <FileField source="src" title="title" />
    </FileInput>
    <FormDataConsumer>
       {({ formData, ...rest }) => formData.anhang.map((a)=>
          <BooleanInput source={'del_'+a.id} label={'lÃ¶sche '+a.title} {...rest} options={{checkedIcon: <DeleteIcon /> }} />
         )
       }
    </FormDataConsumer>
*/

const ShowForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
/*
      {props.record.anhang.length ? <FileField source="anhang" src="url" title="title" target="_blank" style={{width:'100%'}} /> : null }
*/  
  return (
    <SimpleShowLayout toolbar={<CloseToolbar />} {...props}>  
      <TextField source="time_info" label="Datum"/>
      <TextField source="cave_info" label="Höhle"/>
      {props.record.xcavepartlist ? <TextField source="xcavepartlist" label="" addLabel={false}/> : null }
      <TextField source="xactivitylist" label="Aktivitäten"/>
      <TextField source="xuserlist"  label="Teilnehmer"/>
      {props.record.bemerkung ? <TextField source="anzbem" label="Bemerkung"/> : null }
      {props.record.main_comment_id ? <TextField source="comment_subject" label="Titel" /> : null }
      {props.record.main_comment_id ? <RichTextField source="comment_text" stripTags={false} label="" addLabel={false} /> : null }
      <ImgsField />
      <PreviewField source="preview"/>

      <TextField source="owner_name" label="Author"/>
      <TextField source="id_nita" label="ID" />
    </SimpleShowLayout>
  )
};

const AutocompleteNotEmpty = (props) => {
  if (props.choices && props.choices.length) {
    return (
      <AutocompleteArrayInput {...props}/>
    )
  }
  return null;
}

const EditForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const myid = getInfoValue('id');
  if (!isAdmin() && props.record.id_owner!==myid) return (<h2> Keine Berechtigung</h2>);
  
  /*
      <TextField source="xcavepartlist" sortable={false} label="Höhlengebiet"/>
      <TextField source="xactivitylist" sortable={false} label="Aktivitäten"/>
      <TextField source="xuserlist" sortable={false} label="Teilnehmer"/>

      <FormDataConsumer>
         {({ formData, ...rest }) => formData.anhang.map((a)=>
            <BooleanInput source={'del_'+a.id} label={'lÃ¶sche '+a.title} {...rest} options={{checkedIcon: <DeleteIcon /> }} />
           )
         }
      </FormDataConsumer>
  */
  
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}>  
      <FormDataConsumer>
        {({ formData, ...rest }) => 
          <Fragment >
            <TextField source="id" sortable={false} />
            <TextField source="owner_name" sortable={true} label="Author"/>
            <DateInput source="start_datum" sortable={false} label="Datum"/>
            <TextInput source="dauerh" label="Dauer in Stunden" />
            { formData.end_datum || formData.dauer>=10 
              ? <DateInput source="end_datum" sortable={false} label="Datum bis"/>
              : null
            }
            <ReferenceInput label="Höle" source="id_cave" reference="cave" sort={{ field: 'cave_name', order: 'ASC' }} perPage={10000} allowEmpty fullWidth> 
              <AutocompleteInput optionText="cave_name" inputValueMatcher={() => null} />
            </ReferenceInput>
            <ReferenceArrayInput source="xcavepart" reference="cavepart" label="Höhlengebiet" filter={{ id_cave:formData.id_cave }}>
              <AutocompleteNotEmpty optionText="cavepart_name" fullWidth />
            </ReferenceArrayInput>

            <ReferenceArrayInput source="xactivity" reference="activity" label="Aktivitäten">
              <AutocompleteArrayInput optionText="activity_name" fullWidth/>
            </ReferenceArrayInput>
            <ReferenceArrayInput source="xuser" reference="user"  label="Teilnehmer">
              <AutocompleteArrayInput optionText="vornamenametouranz" fullWidth/>
            </ReferenceArrayInput>
            {props.record.bemerkung ? <TextInput source="bemerkung" multiline sortable={false} label="Bemerkung" fullWidth/> : null }
            <TextInput source="comment_subject" label="Titel" fullWidth />
            {formData.comment_subject || formData.comment_text ? <RTextInputAnhang source="comment_text" /> : null }
            
            <FileInput source="files" label="Anhänge" multiple>
              <FileField source="src" title="title" />
            </FileInput>
            <ImgsField {...rest} />
            <PreviewField source="preview" {...rest} />

            {formData.anhang && formData.anhang.map((a)=>
                <BooleanInput source={'del_'+a.id} label={'lösche '+a.title} {...rest} options={{checkedIcon: <DeleteIcon /> }} fullWidth />
              )
            }
          </Fragment >
        }
      </FormDataConsumer>      
    </SimpleForm>
  )
};

export default {
  
  options: { label: "Tour" },
  
  list: (props) => (
    <ListForm {...props} />
  ),
  
  create: (props) => (
    <Create {...props} title="Tour hinzufügen" undoable={false} >
      <SimpleForm toolbar={<CloseToolbar nextstep="edit" />} {...props}> 
        <FormDataConsumer>
          {({ formData, ...rest }) => 
            <Fragment >
              <TextField source="id" sortable={false} />
              <DateInput source="start_datum" sortable={false} label="Datum"/>
              <TextInput source="dauerh" label="Dauer in Stunden" />
              { formData.dauerh>=10 
                ? <DateInput source="end_datum" sortable={false} label="Datum bis"/>
                : null
              }
              <ReferenceInput label="Höhle" source="id_cave" reference="cave" sort={{ field: 'cave_name', order: 'ASC' }} perPage={10000} allowEmpty>
                <AutocompleteInput optionText="cave_name" inputValueMatcher={() => null}  />
              </ReferenceInput>
            </Fragment >
          }
        </FormDataConsumer>      
      </SimpleForm>
    </Create>
  ),
  
  show: (props) => (
    <Show actions={<TourShowActions />} {...props}>
      <ShowForm {...props} />
    </Show>
  ),
  
  edit: (props) => (
    <Edit title="Tour bearbeiten" undoable={false} {...props}>
      <EditForm {...props} />
    </Edit>
  )
}   
