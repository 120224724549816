import React from 'react';
import { Layout } from 'react-admin';
import MyAppBar from './MyAppBar';
//import NestableMenu from './NestableMenu';


//import List from "@material-ui/core/List";
//import classnames from 'classnames';
//import { Responsive } from 'react-admin';
//import {MenuConext} from './MenuContext';

/*
function nestResources(resources, onMenuClick, dense, classes) {
  return (
    <div>
      <MenuConext.Consumer>
        {(ct) => {
          return (
            <NestableMenuList menuct={ct} onMenuClick={onMenuClick} dense={dense} sub={false} />
          )}}
      </MenuConext.Consumer>
    </div>
  );
}
*/
/*
const MyMenu = ({
    classes,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    open,
    pathname,
    resources,
    translate,
    logout,
    ...rest
}) => (
    <div>
      <img src="/logo.svg" style={{width:"100%"}} alt="Logo" />
      <List className={classnames(classes.main, className)} {...rest}>
        <MenuConext>            
          <Responsive xsmall={logout} medium={null} />
        </MenuConext>
      </List>
    </div>
);
*/
/*
const MyMenu = ({
    classes,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    open,
    pathname,
    resources,
    translate,
    logout,
    ...rest
}) => (
    <div>
      <img src="/logo.svg" style={{width:"100%"}} alt="Logo" />
      <List className={classnames(classes.main, className)} {...rest}>
        {nestResources(resources, onMenuClick, dense, classes)}            
          <Responsive xsmall={logout} medium={null} />
      </List>
    </div>
);
*/
const MyLayout = props => <Layout
    {...props}
    appBar={MyAppBar}
/>;
/*
const MyLayout = props => <Layout
    {...props}
    appBar={MyAppBar}
    menu={NestableMenu}
/>;
*/

export default MyLayout;