import React from 'react';
import { List, Create, Edit, Datagrid, SimpleForm, 
  ReferenceInput, SelectInput, TextField, 
  EditButton, TextInput, Button, Filter } from 'react-admin';
import { Link } from 'react-router-dom';
import { Pagination } from 'react-admin';
import { parse } from 'query-string';
import CloseToolbar from './../lib/CloseToolbar';
import { SanitizedDiv } from '../lib/SanitizedForm';
//import ContentAdd from '@material-ui/icons/Add';
import Backspace from '@material-ui/icons/Backspace';

const ListFilter = (props) => (
<Filter {...props} >
  <ReferenceInput source="id_land" label="Land auswählen" reference="land"
    perPage={100} sort={{ field: 'land_name', order: 'ASC' }} alwaysOn  >
    <SelectInput optionText="land_name" />
  </ReferenceInput>
</Filter>
);
      
const PostPagination = props => <SanitizedDiv style={{marginTop:'1em',marginLeft:'1em'}}>
  <ReturnButton />
  <Pagination {...props} />
</SanitizedDiv>

const ReturnButton = ({ 
  record,
  variant = 'contained', 
  icon = <Backspace style={{marginRight: '0.5em', fontSize:'24px'}} />,
}) => (
  <Button
    component={Link}
    to={{
      pathname: 'land',
    }}
    label='ra.action.back'
    variant={variant}
    style={{float:'left', marginRight:'1em'}}
  >
    {icon}
  </Button>
);

const ListForm = ({ permissions, ...props }) => {
  /*actions={<ListActions />}*/
  return (
  <List {...props} title="Region" sort={{ field: 'region_name', order: 'ASC' }} filters={<ListFilter />} pagination={<PostPagination />} filterDefaultValues={{id_land:-1}} exporter={false} >
    <Datagrid style={{ width: 'auto' }}>
      <TextField source="region_name" label="Region"/>
      <TextField source="land_name" label="Land"/>
      <TextField source="cave_anz" label="Höhlen"/>
      <TextField source="tour_anz" label="Touren"/>
      <TextField source="id" />
      {permissions && permissions.isAdmin && <EditButton />}
    </Datagrid>
  </List>
  );
}

const redirect = (basePath, id, data) => {
  if(!data) {
    console.log(parse.pro)
    return "/land/";
  }
  return `/land/${data.id_land}/edit`;
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, ...props}) => {
  const { id_land: id_land_string } = parse(props.location.search);
  const id_land = id_land_string ? parseInt(id_land_string, 10) : '';
  return (
    <SimpleForm initialValues={{ id_land }} toolbar={<CloseToolbar nextstep={redirect} redir={"/land/"+props.record.id_land} />} {...props} >
      <TextField source="id" />
      <ReferenceInput source="id_land" label="Land" reference="land"
        perPage={100} sort={{ field: 'land_name', order: 'ASC' }} >
        <SelectInput optionText="land_name" disabled />
      </ReferenceInput>
      <TextInput source="region_name" label="Region" />
      <TextField source="cave_anz" label="Anzahl Höhlen"/>
      <TextField source="tour_anz" label="Anzahl Touren"/>
    </SimpleForm>
  )
};

export default {
  
  options: { label: "Regionen" },
  
  list: (props) => (
    <ListForm {...props} title="Regionen" />
  ),

  create: (props) => (
    <Create {...props} title="Region hinzufügen">
      <CEForm {...props} />
    </Create>
  ),
  
  edit: (props) => (
    <Edit title="Region bearbeiten" undoable={false} {...props}>
      <CEForm {...props} />
    </Edit>
  )
}   
