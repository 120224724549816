import React from 'react';
import {
  List, Datagrid, TextField, Edit, EditButton, Create, TextInput, SimpleForm,
  Filter
} from 'react-admin';
import CloseToolbar from './../lib/CloseToolbar';
//import { getInfoValue } from './../lib/globalData';

const ListFilter = (props) => (
<Filter {...props} >
  <TextInput source="q" label="Suchen" alwaysOn />
</Filter>
);
      
const ListForm = ({ permissions, ...props }) => {
  return (
  <List {...props} filters={<ListFilter />} sort={{ field: 'section_name', order: 'ASC' }}>
    <Datagrid>
      <TextField source="section_name" label="Sektion"/>
      <TextField source="user_anz" label="Benutzer"/>
      <TextField source="id" />
      {permissions && permissions.isAdmin && <EditButton />}
    </Datagrid>
  </List>
  );
}

const CEForm = props => {
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}>
      <TextField source="id"/>
      <TextInput source="section_name" label="Sektion"/>
      <TextField source="user_anz" label="Anzahl Benutzer"/>
    </SimpleForm>
  );
};

export default {
  options: { label: "Sektionen" },
  
  list: (props) => (
    <ListForm {...props} title="Sektionen" />
  ),
  
  create: (props) => (
    <Create {...props} title="Sektion hinzufügen">
      <CEForm {...props} />
    </Create>
  ),
  
  edit: (props) => (
    <Edit title="Sektion bearbeiten" undoable={false} {...props}>
      <CEForm {...props} />
    </Edit>
  )
};
