import React from 'react';
import Grid from '@material-ui/core/Grid';

const SanitizedGrid = ({basePath, ...props}) => {
  return (
    <Grid {...props} />
  );
};
const SanitizedDiv = ({basePath, ...props}) => {
  return (
    <div {...props} />
  );
};
const SanitizedP = ({basePath, ...props}) => {
  return (
    <p {...props} />
  );
};
export { SanitizedGrid, SanitizedDiv, SanitizedP }