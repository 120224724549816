// Abgeshlossene Isidents grünn setzen
// https://www.fusioncharts.com/blog/analytical-dashboard-react-graphql/
import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
//import ListItem from '@material-ui/core/ListItem';
import { translate } from 'react-admin';
import Button from '@material-ui/core/Button';
import NotificationImportant from '@material-ui/icons/NotificationImportant';
import Timeline from '@material-ui/icons/Timeline';
//import WarningIcon from '@material-ui/icons/Warning';
//import HourglassIcon from '@material-ui/icons/HourglassFull';
//import InfoIcon from '@material-ui/icons/Info';
import { ResponsiveContainer, AreaChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Area } from 'recharts';
import withWidth from '@material-ui/core/withWidth';

import dataProvider from './dataProvider';
import CardIcon from './CardIcon';
//import CheckInIcon from 'mdi-react/ArrowBottomRightIcon'
//import CheckOutIcon from 'mdi-react/ArrowTopRightIcon'
//import GuestsIcon from 'mdi-react/AccountSupervisorIcon'


const styles = {
    flex: { display: 'flex' },
    flexTop: { display: 'flex', marginTop:'1em' },
    flexMargin: { display: 'flex', marginBottom: "1em" },
    flexSpace: { display: 'flex', justifyContent: 'space-between' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    topButton: { marginRight: "1em" },
    cardWithIcon: { padding: "16px 0", overflow: "inherit", minHeight:'5em', marginBottom:'3em', },
    homInfoMain: { borderTop: "1px solid #aaa"},
    tab: { marginLeft: '16px', },
    tab1: { minWidth: '90px', marginRight:'6px'},
};

const Notification = ({ homeinfo }) => (
  <div>
    <CardIcon Icon={NotificationImportant} bgColor="#FF9800" />
    <Card style={styles.cardWithIcon}>
      <List>
        <h3>Benutzerregeln & Hilfe</h3>
        <h4>Benutzerregeln</h4>
        <h5>F&uuml;r Mitglieder gilt:</h5>
        <ul>
          <li>Dein Zugang zu dieser Seite ist pers&ouml;nlich und darf nicht weitergegeben werden - auch nicht an andere Klubmitglieder. </li>
          <li>Du darfst gerne den <strong>Gast-Zugang</strong> an alle - auch nicht-Mitglieder - weitergeben: <strong>Benutzername und Passwort bitte beim Administrator nachfragen</strong>. </li>
          <li>Alle in dieser Datenbank und in ihrem Zusammenhang gefundenen Informationen, deren Autor du nicht selbst bist, d&uuml;rfen nicht ohne die ausdr&uuml;ckliche Zustimmung des Autors oder eines Vorstandsmitglieds  ver&ouml;ffentlicht oder in irgend einer anderen Form weiterverwendet werden.</li>
          <li>Die Sektion &uuml;bernimmt keine Haftung f&uuml;r irgendwelche Art von Informationen, die hier gefunden werden k&ouml;nnen. Als AutorIn bist und bleibst du Eigent&uuml;merIn deiner Beitr&auml;ge und bist f&uuml;r deren Inhalt selbst verantwortlich.</li>
          <li>S&auml;mtliche Zugriffe k&ouml;nnen aufgezeichnet werden.</li>
        </ul>      
        <h5>F&uuml;r G&auml;ste gilt:</h5>
        <ul>
          <li>Alle in dieser Datenbank und in ihrem Zusammenhang gefundenen Informationen, deren Autor du nicht selbst bist, d&uuml;rfen nicht ohne die ausdr&uuml;ckliche Zustimmung des Autors oder eines Vorstandsmitglieds ver&ouml;ffentlicht oder in irgend einer anderen Form weiterverwendet werden.</li>
          <li>Die Sektion &uuml;bernimmt keine Haftung f&uuml;r irgendwelche Art von Informationen, die hier gefunden werden k&ouml;nnen. Die Autoren sind Eigent&uuml;mer ihrer Beitr&auml;ge und f&uuml;r deren Inhalt selbst verantwortlich.</li>
          <li>S&auml;mtliche Zugriffe k&ouml;nnen aufgezeichnet werden.</li>
        </ul>      

        <p>Bei Fragen, die hier nicht beantwortet sind, einfach eine Mail an <a href="mailto:webmaster@agn-solothurn.ch">webmaster@agn-solothurn.ch</a> schreiben.</p>      
      </List>              
    </Card>
  </div>
);

/*      
      <table width="100%"  border="0" cellspacing="0" cellpadding="0">
        <tr>
          <td colspan="2"><span class="symbol">?</span><span class="tab-title"><span class="tab-title"> In welchen Feldern kann ich suchen? </span></span></td>
        </tr>
        <tr>
          <td colspan="2" height="5"><img src="../images/blind.gif" width="1" height="5"></td>
        </tr>
        <tr valign="top">
          <td width="30"><div align="right"><img src="../images/Next.gif" width="14" height="13">&nbsp;</div></td>
          <td> In allen Feldern, bei denen unter dem Feldtitel ein weisses Eigabek&auml;stchen angezeigt wird, kann man suchen.</td>
        </tr>
      </table>
      <br>
      <table width="100%"  border="0" cellspacing="0" cellpadding="0">
        <tr>
          <td colspan="2"><span class="symbol">?</span><span class="tab-title"><span class="tab-title"> Wie kann ich suchen?</span></span></td>
        </tr>
        <tr>
          <td colspan="2" height="5"><img src="../images/blind.gif" width="1" height="5"></td>
        </tr>
        <tr valign="top">
          <td width="30"><div align="right"><img src="../images/Next.gif" width="14" height="13">&nbsp;</div></td>
          <td> <p>Den Suchbegriff in das Suchk&auml;stchen eingeben.  Gross-/Kleinschreibung wird immer ignoriert. <br>
            Es gelten folgende Regeln:</p>
            <table border="0" cellspacing="1" cellpadding="1">
              <tr class="tabs_selected">
                <td>Eingabe von</td>
                <td>Findet dies</td>
              </tr>
              <tr class="tabs_normal">
                <td><em>ausdruck</em></td>
                <td>enth&auml;lt genau den Suchbegriff <em>ausdruck</em></td>
              </tr>
              <tr class="tabs_normal">
                <td><em>ausdruck</em>%</td>
                <td> f&auml;ngt mit <em>ausdruck</em> an </td>
              </tr>
              <tr class="tabs_normal">
                <td>%<em>ausdruck</em></td>
                <td> h&ouml;rt mit <em>ausdruck</em> auf </td>
              </tr>
              <tr class="tabs_normal">
                <td>%<em>ausdruck</em>%</td>
                <td>enth&auml;lt <em>ausdruck</em> irgednwo im Feld</td>
              </tr>
              <tr class="tabs_normal">
                <td>&gt;<em>ausdruck</em></td>
                <td>ist gr&ouml;sser als <em>ausdruck</em> </td>
              </tr>
              <tr class="tabs_normal">
                <td>&lt;<em>ausdruck</em></td>
                <td>ist keliner als <em>ausdruck</em> </td>
              </tr>
              <tr class="tabs_normal">
                <td>&gt;=<em>ausdruck</em></td>
                <td>ist gr&ouml;sser oder gleich <em>ausdruck</em> </td>
              </tr>
              <tr class="tabs_normal">
                <td>&lt;=<em>ausdruck</em></td>
                <td>ist kelineroder gleich <em>ausdruck</em> </td>
              </tr>
              <tr class="tabs_normal">
                <td>!<em>ausdruck</em> oder &lt;&gt;<em>ausdruck</em> </td>
                <td>ist ungleich <em>ausdruck</em> </td>
              </tr>
              <tr class="tabs_normal">
                <td>_</td>
                <td>Platzhalter f&uuml;r einen einzelnen Buchstaben </td>
              </tr>
              <tr class="tabs_normal">
                <td>!</td>
                <td>negiert den folgenden Ausdruck (z.B. &quot;!&lt;&quot; entspricht &quot;&gt;&quot; </td>
              </tr>
            </table>            </td>
        </tr>
      </table>
      <br>
      <table width="100%"  border="0" cellspacing="0" cellpadding="0">
        <tr>
          <td colspan="2"><span class="symbol">? </span><span class="tab-title">Wie suche ich in mehreren Feldern gleichzeitig? </span></td>
        </tr>
        <tr>
          <td colspan="2" height="5"><img src="../images/blind.gif" width="1" height="5"></td>
        </tr>
        <tr valign="top">
          <td width="30"><div align="right"><img src="../images/Next.gif" width="14" height="13">&nbsp;</div></td>
          <td> Wenn bei einer Tabelle die M&ouml;glichkeit besteht, bei mehreren Suchfeldern Suchbegriffe einzugeben, so k&ouml;nnen beliebig viele dieser Felder zu verkn&uuml;pften Suchabfragen verbunden werden. Es besteht dabei immer eine &quot;UND&quot;-Verkn&uuml;pfung.<br>
            Beispiel: Wenn beim Feld <strong>Datum</strong> der Suchbegriff &quot;&gt;=2000&quot; und beim Feld <strong>H&ouml;hle/Ort</strong> der Suchbegriff &quot;%hagl&auml;tsch%&quot; eingegeben wird, heisst dies im Klartext: &quot;Zeige alle Hagl&auml;tsch-Touren seit 1.1.2000&quot; .</td>
        </tr>
      </table>
*/      


class dashboard extends Component {
    state = {
      start:1,
      duration:6,
      stats: {},
      dispData: {
        sections: [],
        touren: [],
      },
    };
    
    loadDiagram = () => {
      dataProvider.getList('dashboard', {
          id: 0,
          filter: { start: this.state.start, duration: this.state.duration },
          sort: { field: 'date', order: 'DESC' },
          pagination: { page: 1, perPage: 50 },
      })
        .then(response => {
          if (response.data && response.data.touren) {
            this.setState({dispData:response.data});
          }
        });
    }

    componentDidMount() {
      this.loadDiagram();
    }

    handleButton = ev=> {
      const v = ev.currentTarget.value;
      this.setState({duration: parseInt(v)}, this.loadDiagram);
    }

    render() {
      //const { translate, width } = this.props;
      const { width } = this.props;
      const {
        //start,
        duration,
        //stats,
        dispData,
        homeinfo,
        } = this.state;
      if (dispData.sections.length<1) {
        return null;
      }
      const intervalle = {3:37,6:73,12:145,99:1190};
      return (  
    <div>
      {width!=='lg' && width!=='xl'?<div style={styles.leftCol}><Notification homeinfo={homeinfo} /></div>:null}
      <div style={styles.flexTop}>
        {width==='lg'||width==='xl'?<div style={styles.rightCol}><Notification homeinfo={homeinfo} /></div>:null}
        <div style={styles.leftCol}>
          <CardIcon Icon={Timeline} bgColor="#31708F" />
          <Card style={styles.cardWithIcon}>
            <CardContent>
              <h3>Touren-Einträge pro Monat</h3>
              <div style={styles.flexMargin}>
                <Button variant={duration===3?"contained":"outlined"} color={duration===3?"primary":"default"} style={styles.topButton} value="3" onClick={this.handleButton}>3 Jahre</Button>
                <Button variant={duration===6?"contained":"outlined"} color={duration===6?"primary":"default"} style={styles.topButton} value="6" onClick={this.handleButton}>6 Jahre</Button>
                <Button variant={duration===12?"contained":"outlined"} color={duration===12?"primary":"default"} style={styles.topButton} value="12" onClick={this.handleButton}>12 Jahre</Button>
                <Button variant={duration===99?"contained":"outlined"} color={duration===99?"primary":"default"} style={styles.topButton} value="99" onClick={this.handleButton}>99 Jahre</Button>
              </div>  
              <ResponsiveContainer width="100%" aspect={2} >
                <AreaChart data={dispData.touren}
                      margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis dataKey="datum" interval={intervalle[duration]}/>
                  <YAxis/>
                  <Tooltip/>
                  <Legend />
                  {dispData.sections.map(el => (
                    <Area type='linear' dataKey={el.section_id} key={el.section_id} stackId="1" stroke={el.farbe} fill={el.farbe} activeDot name={el.bezeichnung} />
                    )
                  )}
                </AreaChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </div>
      </div>        
    </div>        
      );
    }
}
export default translate(withWidth()(dashboard));

