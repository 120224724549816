import React, { Component } from 'react';
import { isSuper, isAdmin } from './../lib/globalData';
import { List, Create, Edit, Datagrid, SimpleForm, ReferenceInput, AutocompleteInput, 
         ReferenceManyField, DeleteButton,
         TextField, EditButton, Filter, Button, TextInput, 
       } from 'react-admin';
import CloseToolbar from './../lib/CloseToolbar';
import { Link } from 'react-router-dom';
//import KingBed from '@material-ui/icons/KingBed';
//import { stringify } from 'query-string';
import AddIcon from '@material-ui/icons/Add';

const ListFilter = (props) => (
<Filter {...props} >
  <TextInput source="q" label="Suchen" alwaysOn />
  <ReferenceInput source="id_region" label="Region" reference="region"
    perPage={10} sort={{ field: 'countryregion_name', order: 'ASC' }} alwaysOn>
    <AutocompleteInput optionText="countryregion_name" inputValueMatcher={() => null} />
  </ReferenceInput>
  <ReferenceInput source="id_land" label="Land" reference="land"
    perPage={10} sort={{ field: 'land_name', order: 'ASC' }} alwaysOn>
    <AutocompleteInput optionText="land_name" inputValueMatcher={() => null} />
  </ReferenceInput>
</Filter>
);
  
const AddGebietButton = ({ classes, record }) => (
  <Button
    variant="contained"
    component={Link}
    to={`/cavepart/create?id_cave=${record.id}`}
    label="Gebiet hinzufügen"
    title="Höhlengebiete"
  >
    <AddIcon />
  </Button>
);

const DeleteGebietButton = ( props ) => {
  return (
    <DeleteButton redirect={"/cave/"+props.record.id_cave} {...props} />
  );
}
      
class ListForm extends Component {
  constructor(props) {
    super(props);
    this.state = {moveStart: 0};
  }
  render() {
    return (
      <List {...this.props} filters={<ListFilter />} title="Höhlen"  sort={{ field: 'cave_name', order: 'ASC' }} exporter={isSuper() && this.props.exporter} >
        <Datagrid style={{ width: 'auto' }} >
          <TextField source="cave_name" sortable={true} label="Höhle"/>
          <TextField source="kanton_land" sortable={true} label="Region"/>
          <TextField source="cavepart_list" sortable={true} label="Gebiete"/>
          <TextField source="tour_anz" sortable={true} label="Touren"/>
          <TextField source="last_tour" sortable={true} label="Letzte Tour"/>
          <TextField source="id" sortable={false} />
          {isAdmin()?<EditButton />:null}
        </Datagrid>
      </List>
    )
  }
}

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, keinGebiet, ...props}) => {
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}> 
      <TextField source="id" />
      <TextInput source="cave_name" label="Höhle" fullWidth/>
      <ReferenceInput label="Land / Region" source="id_region" reference="region" sort={{ field: 'countryregion_name', order: 'ASC' }} perPage={1000} allowEmpty>
        <AutocompleteInput optionText="countryregion_name" inputValueMatcher={() => null}  />
      </ReferenceInput>
      {!keinGebiet?
         [
          <ReferenceManyField
            addLabel={false}
            reference="cavepart"
            target="id_cave"
            sort={{ field: 'cavepart_name', order: 'ASC' }}
            redirect="edit"
            fullWidth
          >
            <Datagrid>
              <TextField source="cavepart_name" label="Gebiet" />
              <TextField source="tour_anz" label="Anzahl Touren" />
              <TextField source="last_tour" label="Letzte Tour" />
              <EditButton />
              <DeleteGebietButton />
            </Datagrid>
          </ReferenceManyField>,
          <AddGebietButton />
         ]
       : null
      }
      <TextField source="tour_anz" label="Anzahl Touren"/>
      <TextField source="last_tour" label="Letzte Tour"/>
    </SimpleForm>
  )
};

export default {
  
  options: { label: "Höhlen" },
  
  list: (props) => (
    <ListForm {...props} />
  ),
  
  create: (props) => (
    <Create {...props} title="Höhle hinzufügen">
      <CEForm {...props} keinGebiet />
    </Create>
  ),
  
  edit: (props) => (
    <Edit title="Höhle bearbeiten" undoable={false} {...props}>
      <CEForm {...props} />
    </Edit>
  )
}   
