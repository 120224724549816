import React from 'react';
//import React, { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
//import { Layout } from 'react-admin';
import dataProvider from './lib/dataProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import germanMessages from 'ra-language-german';
import MyLayout from './lib/MyLayout';
import Login from './lib/Login';
import authProvider from './lib/authProvider';

import Dashboard from './lib/dashboard';
import Tour from './pages/tour';
import Cave from './pages/cave';
import Cavepart from './pages/cavepart';
import Land from './pages/land';
import Region from './pages/region';
import Activity from './pages/activity';
import User from './pages/user';
import Section from './pages/section';

import iTour from '@material-ui/icons/FilterHdr';
import iCave from '@material-ui/icons/PlayForWork';
import iCountry from '@material-ui/icons/Public';
import iWork from '@material-ui/icons/Work';
import iPerson from '@material-ui/icons/Person';
import iSection from '@material-ui/icons/People';
//import iSection from 'mdi-react/AccountGroupIcon';

// https://material.io/tools/icons/?style=baseline
// https://materialdesignicons.com

import './App.css';

const i18nProvider = polyglotI18nProvider(() => germanMessages, 'de');
const App = () => {
  return (
    <Admin 
      title="AGN-Tourendatenbank"  
      i18nProvider={i18nProvider}
      // customRoutes={customRoutes} 
      // catchAll={catchAll} 
      authProvider={authProvider} 
      layout={MyLayout}
      loginPage={Login}
      dataProvider={dataProvider} 
      dashboard={Dashboard}
    >
    {permissions => {
      const isAdmin = permissions && permissions.isAdmin;
      return [
      <Resource name="tour" icon={iTour} {...Tour} />,

      <Resource name="cave" icon={iCave} 
                options={Cave.options}
                list={Cave.list} 
                edit={isAdmin?Cave.edit:null} 
                create={isAdmin?Cave.create:null} 
      />,
      isAdmin 
        ? <Resource name="cavepart" {...Cavepart} />
        : <Resource name="cavepart" />
      ,
        
      <Resource name="land" icon={iCountry} 
                options={Land.options}
                list={Land.list} 
                edit={isAdmin?Land.edit:null} 
                create={isAdmin?Land.create:null} 
      />,
      isAdmin 
        ? <Resource name="region" {...Region} />
        : <Resource name="region" />
      ,

      isAdmin 
        ? [
          <Resource name="activity" icon={iWork} {...Activity} />,
          <Resource name="user" icon={iPerson} {...User} />,
          <Resource name="section" icon={iSection} {...Section} /> 
        ]
        : [
          <Resource name="activity" />,
          <Resource name="user" />,
          <Resource name="section" />,
        ],
        
      <Resource name="usergroup" />,
    ]}}
    </Admin>
  )
};
export default App;

/*

*/