import React from 'react';
import { AppBar, UserMenu } from 'react-admin';

import isTest from './isTest';
import {MenuConext} from './MenuContext';
import { getGlobalData } from './globalData';
//import MainMenuItem from './MainMenuItem';
//import SwitchHostel from './SwitchHostel';
//import SwitchLang from './SwitchLang';
//import GuiVersion from '../version';

//import HomeIcon from '@material-ui/icons/Home';
//import CalendarIcon from '@material-ui/icons/CalendarToday';
//import ListAlt from '@material-ui/icons/ListAlt';
//import Language from '@material-ui/icons/Language';
//import SettingsIcon from '@material-ui/icons/Settings';
//import FullscreenIcon from '@material-ui/icons/Fullscreen';
//import LogoAGN from '../AGN_logo_128.svg';
import LogoAGN from '../AGN_logo_def2.svg';
import Typography from '@material-ui/core/Typography';

//import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginLeft: 0
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxWidth: "40px",
    marginLeft: 10,
    marginRight: 10
  },
});

const MyUserMenu = props => (
    <UserMenu {...props}>
    </UserMenu>
);
/*
const MyUserMenu = props => (
    <UserMenu {...props}>
        <SwitchLang />
    </UserMenu>
);
*/
const MyAppBar = props => {
  const classes = useStyles();
  return (
    <AppBar {...props} color="primary" style={isTest?{backgroundColor:"#D02628"}:{}} userMenu={<MyUserMenu />}>
      <Typography variant="h6" >AGN-Tourendatenbank</Typography>
      <img src={LogoAGN} className={classes.logo} alt="Logo AGN" />
      <Typography variant="h6" id="react-admin-title" />
      <MenuConext.Consumer>
        {({activeMain, updateMain}) => {
          const info = getGlobalData('info');
          var mainButtons = [];
          mainButtons.push(<span key="space" style={{flex: 1}} />);
          mainButtons.push(
            <div style={{position:'absolute',right:'1em',top:'55px',color:'#000'}} key="info">
              {info && (info.gruppe<4 || info.back)?' '+info.username+' ['+info.gruppename+'] / '+info.host:' '}
            </div>
          );
          return mainButtons;
        }}
      </MenuConext.Consumer>
    </AppBar>
  );
}
/*
const MyAppBar = props => (
    <AppBar {...props} color="primary" style={isTest?{backgroundColor:"#D02628"}:{}} userMenu={<MyUserMenu />}>
      <MenuConext.Consumer>
        {({activeMain, updateMain}) => {

          const info = getGlobalData('info');
          const icons = {home:<HomeIcon />, kalender:<CalendarIcon />, report:<ListAlt />, online:<Language />, settings:<SettingsIcon />, glob:<FullscreenIcon />}
          const menu = getGlobalData('menu');
          var mainpos = 0;
          var actmenu = 0;
          var lastmenu = localStorage.getItem('lastmenu');
          if (lastmenu !== null) {
            if (activeMain===-1) {
              actmenu = parseInt(lastmenu);
            }
            else {
              actmenu = activeMain;
            }
          }
          var mainButtons = [];
          for(var k in menu) {
            var m = menu[k];
            if (m.id_parent===0) {
              var key = m.route;
              if (!key || !icons[key]) {
                key = 'home';
              }
              mainButtons.push(<MainMenuItem key={key} route={m.route} onClick={updateMain} pos={mainpos} label={m.bez} icon={icons[key]} aktiv={actmenu===mainpos} />);
              mainpos++;
            }
          }
          mainButtons.push(<span key="space" style={{flex: 1}} />);
          if (info && info.canSwitch) {
            mainButtons.push(<SwitchHostel key="switch" />);
          }
          mainButtons.push(
            <div style={{position:'absolute',right:'1em',top:'55px',color:'#000'}} key="info">
              <a href="http://docs.dormproject.ch/de-konfiguration.html?benutzername=dphostel&passwort=sqs8MBX5" target="_blank" rel="noopener noreferrer">
                Documentation
              </a>
              {info && (info.gruppe<4 || info.back)?' '+info.username+'@'+info.hostelname+'['+
                info.id_hostel+'] / '+ GuiVersion+'->'+info.apiversion+'@'+info.host+'->'+info.dbname:' '}
            </div>
          );
          return mainButtons;
        }}
      </MenuConext.Consumer>
      
    </AppBar>
)
*/
export default MyAppBar;

