import React, { Component } from 'react';
import { isSuper, isAdmin } from './../lib/globalData';
import { List, Create, Edit, Datagrid, SimpleForm, Filter, 
         ReferenceManyField, DeleteButton, regex,
         TextField, EditButton, Button, TextInput } from 'react-admin';
import CloseToolbar from './../lib/CloseToolbar';
import { Link } from 'react-router-dom';
//import KingBed from '@material-ui/icons/KingBed';
//import { stringify } from 'query-string';
import AddIcon from '@material-ui/icons/Add';

const ListFilter = (props) => (
<Filter {...props} >
  <TextInput source="q" label="Suchen" alwaysOn />
</Filter>
);
      
const AddRegionButton = ({ classes, record }) => (
  <Button
    variant="contained"
    component={Link}
    to={`/region/create?id_land=${record.id}`}
    label="Region hinzufügen"
    title="Regionen"
  >
    <AddIcon />
  </Button>
);

const DeleteRegionButton = ( props ) => {
  return (
    <DeleteButton redirect={"/land/"+props.record.id_land} {...props} />
  );
}

class ListForm extends Component {
  constructor(props) {
    super(props);
    this.state = {moveStart: 0};
  }
  render() {
    return (
      <List {...this.props} title="Länder"  sort={{ field: 'tour_anz', order: 'DESC' }} exporter={isSuper() && this.props.exporter} filters={<ListFilter />} >
        <Datagrid style={{ width: 'auto' }} >
          <TextField source="land_name" sortable={false} label="Land" />
          <TextField source="iso" sortable={false} />
          <TextField source="regionlist" sortable={false} label="Regionen"/>
          <TextField source="cave_anz" sortable={false} label="Höhlen"/>
          <TextField source="tour_anz" sortable={false} label="Touren"/>
          <TextField source="id" sortable={false} />
          {isAdmin()?<EditButton />:null}
        </Datagrid>
      </List>
    )
  }
}

const validateISO = regex(/^[A-Z]{3}$/, 'Genau 3 Grossbuchstaben');

const CEForm = ({classes, hasList, hasEdit, hasCreate, hasShow, keineRegion, ...props}) => {
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}> 
      <TextField source="id" />
      <TextInput source="land_name" label="Land"/>
      <TextInput source="iso" label="ISO Abkürzung (3 Buchstaben)" validate={validateISO}/>
      {!keineRegion?
         [
          <ReferenceManyField
            addLabel={false}
            reference="region"
            target="id_land"
            sort={{ field: 'region_name', order: 'ASC' }}
            redirect="edit"
            fullWidth
          >
            <Datagrid>
              <TextField source="region_name" label="Region" />
              <TextField source="cave_anz" label="Anzahl Höhlen" />
              <TextField source="tour_anz" label="Anzahl Touren" />
              <EditButton />
              <DeleteRegionButton />
            </Datagrid>
          </ReferenceManyField>,
          <AddRegionButton />
         ]
       : null
      }
    </SimpleForm>
  )
};

export default {
  
  options: { label: "Länder" },
  
  list: (props) => (
    <ListForm {...props} />
  ),
  
  create: (props) => (
    <Create {...props} title="Land hinzufügen">
      <CEForm {...props} keineRegion />
    </Create>
  ),
  
  edit: (props) => (
    <Edit title="Land bearbeiten" undoable={false} {...props}>
      <CEForm {...props} />
    </Edit>
  )
}   
