const setGlobalData = data => {
  if (!window.globalData) {
    window.globalData = {};
  }
  window.globalData = {...window.globalData, ...data};
}

const getGlobalData = key => {
  if (!window.globalData) {
    window.globalData = {};
  }
  if (!window.globalData[key]) {
    var data = {};
    if (key==='token') {
      data = localStorage.getItem('token');
      setGlobalData({token:data});
      return data;
    }
    if (key==='info') {
      try {
        data = JSON.parse(localStorage.getItem('info'));
        setGlobalData({info:data});
        return data;
      }
      catch (e) {
        return {};
      }
    }
    return {}
  }
  return window.globalData[key];
}

const getInfoValue = key => {
  const info = getGlobalData('info');
  if(!info[key]) {
    return false;
  }
  return info[key];
}

const getRoute = () => {
  var r = window.location.hash;
  while (r[0]==='#' || r[0]==='/') {
    r = r.substr(1)
  }
  return r;
}

const isSuper = () => {
  return getInfoValue('isSuper');
}

const isAdmin = () => {
  return getInfoValue('isAdmin');
}

const textileConvert = (text) => {
  var t = window.textile(text);
  t = t.replace(/<a href=/g, '<a target="_blank" href=');
  return t;
};

const splitText = (s) => {
  var titel = '';
  var text = '';
  if (!s) {
    return {titel, text};
  }
  var h1 = s.indexOf('h1. ');
  if (0 <= h1) {
    var nl = s.indexOf("\n", h1);
    if (0 <= nl) {
      titel = s.substr(h1+4, nl-h1-4);
      text = s.substr(nl+1);
    }
    else {
      titel = s.substr(h1+4);
    }
  }
  else {
    text = s;
  }
  
  return {titel, text};
};

export { setGlobalData, getGlobalData, getInfoValue, getRoute, isSuper, isAdmin, textileConvert, splitText };
