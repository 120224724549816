import React from 'react';
import { translate, SaveButton, Toolbar } from 'react-admin';
import CloseButton from './CloseButton';

const CloseToolbar = ({noSave, translate, redir, nextstep, ...props})=> {
  return (
    <Toolbar {...props} >
      {!noSave?
        <SaveButton
            label={translate("ra.action.save")}
            redirect={nextstep?nextstep:"list"}
            submitOnEnter={true}
            style={{marginRight:'1em'}}
        />
        :null
      }
        <CloseButton
            label="ra.action.back"
            redirect={redir?redir:null}
        />
    </Toolbar>
  );
 
}
export default translate(CloseToolbar);

