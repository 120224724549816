import React from 'react';
import {
  List, Datagrid, TextField, Edit, EditButton, Create, TextInput, SimpleForm, 
  Filter
} from 'react-admin';
import CloseToolbar from './../lib/CloseToolbar';

const ListFilter = (props) => (
<Filter {...props} >
  <TextInput source="q" label="Suchen" alwaysOn />
</Filter>
);

const ListForm = props => {
  return (
  <List {...props} filters={<ListFilter />} sort={{ field: 'activity_name', order: 'ASC' }}>
    <Datagrid>
      <TextField source="activity_name" label="Aktivität" />
      <TextField source="tour_anz" label="Touren" />
      <TextField source="last_tour" label="Letzte Tour" />
      <TextField source="id" />
      <EditButton />
    </Datagrid>
  </List>
  );
}

const CEForm = props => {
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}>
      <TextField source="id"/>
      <TextInput source="activity_name" label="Aktivität" />
      <TextField source="tour_anz" label="Anzahl Touren" />
      <TextField source="last_tour" label="Letzte Tour" />
    </SimpleForm>
  );
};

export default {
  
  options: { label: "Aktivitäten" },
  
  list: (props) => (
    <ListForm {...props} title="Aktivitäten" />
  ),
  
  create: (props) => (
    <Create {...props} title="Aktivität hinzufügen">
      <CEForm {...props} />
    </Create>
  ),
  
  edit: (props) => (
    <Edit title="Aktivität bearbeiten" undoable={false} {...props}>
      <CEForm {...props} />
    </Edit>
  )
}   
