import simpleRestProvider from 'ra-data-simple-rest';
import { getGlobalData } from './globalData';
import { apiPath } from './isTest';
import { fetchUtils } from 'react-admin';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  var token = getGlobalData('token');
  if (!token) {
    token = localStorage.getItem('token')
  }
  //options.headers.set('Authorization', token);
  options.headers.set('TourenToken', token); // alternative weil hosttech authorization filtert!
  return fetchUtils.fetchJson(url, options);
}
const dataProvider = simpleRestProvider(apiPath, httpClient);

const myDataProvider = {
    ...dataProvider,
    update: (resource, params) => {
      if (!params.data.files) {
        return dataProvider.update(resource, params);
      }
      
      const newFiles = params.data.files.filter(
        p => p.rawFile instanceof File
      );
      const formerFiles = params.data.files.filter(
        p => !(p.rawFile instanceof File)
      );
      return Promise.all(newFiles.map(convertFileToBase64))
        .then(base64Files =>
            base64Files.map(file64 => ({
                src: file64.enc,
                title: file64.title,
            }))
        )
        .then(transformedNewFiles =>
            dataProvider.update(resource, {
                ...params,
                data: {
                    ...params.data,
                    files: [
                        ...transformedNewFiles,
                        ...formerFiles,
                    ],
                },
            })
        );
    },

    create: (resource, params) => {
      if (!params.data.files) {
        return dataProvider.create(resource, params);
      }
      const newFiles = params.data.files.filter(
        p => p.rawFile instanceof File
      );
      const formerFiles = params.data.files.filter(
        p => !(p.rawFile instanceof File)
      );
      return Promise.all(newFiles.map(convertFileToBase64))
        .then(base64Files =>
            base64Files.map(file64 => ({
                src: file64.enc,
                title: file64.title,
            }))
        )
        .then(transformedNewFiles =>
            dataProvider.create(resource, {
                ...params,
                data: {
                    ...params.data,
                    files: [
                        ...transformedNewFiles,
                        ...formerFiles,
                    ],
                },
            })
        );
    }
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.rawFile);

        reader.onload = () => resolve({title: file.title, enc: reader.result});
        reader.onerror = reject;
    });

export default myDataProvider;
