const isTest = window.location.hostname !== 'touren.agn-solothurn.ch';
var apiPath = '';
switch(window.location.hostname) {
  case 'touren.agn-solothurn.ch':
    apiPath = 'https://touren.agn-solothurn.ch/api';
    break;
  
  case 'localhost':
    apiPath = 'https://agntouren.alixon.ch/api';
    break;
  
  default:
    apiPath = 'https://agntouren.alixon.ch/api';
  break;
  
}
//apiPath = 'https://touren.agn-solothurn.ch/api';

export { apiPath };

export default isTest;