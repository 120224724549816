import React from 'react';
//import React, { Fragment } from 'react';
import {
  List, Datagrid, TextField, ReferenceField, 
  Edit, EditButton, email, Filter, ReferenceInput, 
  Create, TextInput, SelectInput, SimpleForm, 
  Button, required
} from 'react-admin';
import CloseToolbar from './../lib/CloseToolbar';
import dataProvider from './../lib/dataProvider';
import { isAdmin, isSuper, setGlobalData } from './../lib/globalData';
//import { Link } from 'react-router-dom';
import Transform from '@material-ui/icons/Transform';
//import { stringify } from 'query-string';
//import Send from '@material-ui/icons/Send';

const CloneButton = (props) => {
  const handleClickOpen = () => {
    dataProvider.getOne('authSwitch', {
        id: props.record.id,
    })
      .then(res => {
        setGlobalData({token:res.data.token, info:res.data.info});
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('info', JSON.stringify(res.data.info));
        window.location.href = '/';
      })
      .catch(response => {
        window.location.hash = '/login';
      });
    
  }

  if (!isAdmin()) return null;
  if (!isSuper() && props.record.isSuper) return null;
  
  return (
    <Button label='Clone' onClick={handleClickOpen}>
      <Transform />
    </Button>
  );
}

const CondEditButton = (props) => {
  if (!isSuper() && props.record.isSuper) return null;
  return (
    <EditButton {...props} />
  )
}

const ListFilter = (props) => (
<Filter {...props} >
  <TextInput source="q" label="Suchen" alwaysOn resettable />
  <ReferenceInput source="id_section" label="Sektion" reference="section"
    perPage={100} sort={{ field: 'section_name', order: 'ASC' }} >
    <SelectInput optionText="section_name" resettable allowEmpty />
  </ReferenceInput>
  <ReferenceInput source="id_usergroup" label="Benutzergruppe" reference="usergroup"
    perPage={10} sort={{ field: 'usergroup_name', order: 'ASC' }} alwaysOn>
    <SelectInput optionText="usergroup_name" resettable allowEmpty />
  </ReferenceInput>
  <SelectInput source="usernamefilter"  label="Benutzername" choices={[{id:0,name:''},{id:1,name:'mit Benutzername'},{id:2,name:'ohne Benutzername'}]} alwaysOn resettable/>
  <SelectInput source="tourOwner"  label="Authoren" choices={[{id:0,name:''},{id:1,name:'Authoren'}]} alwaysOn resettable/>
  <SelectInput source="tourTn"  label="Teilnehmer" choices={[{id:0,name:''},{id:1,name:'Teilnehmer'}]} resettable/>
</Filter>
);
      
const ListForm = props => {
  return (
  <List {...props} filters={<ListFilter />} sort={{ field: 'benutzername', order: 'DESC' }}>
    <Datagrid>
      <TextField source="benutzername" label="E-Mail"/>
      <TextField source="vornamename" />
      <ReferenceField source="id_section" reference="section" linkType={false} label="Sektion">
        <TextField source="section_name" />
      </ReferenceField>
      <ReferenceField source="id_usergroup" reference="usergroup" linkType={false} label="Benutzergruppe">
        <TextField source="usergroup_name" />
      </ReferenceField>
      <TextField source="tour_anz"  label="Berichte"/>
      <TextField source="tourtn_anz"  label="Touren"/>
      <TextField source="last_tour"  label="Letzte Tour"/>
      <TextField source="last_connect" label="Letztes Login"/>
      <TextField source="id" />
      <CondEditButton />
      <CloneButton />
    </Datagrid>
  </List>
  );
}

const CEForm = props => {
  return (
    <SimpleForm toolbar={<CloseToolbar />} {...props}>
      <TextField source="id"/>
      <TextInput source="benutzername" label="Benutzername / E-Mail"validate={email()}/>
      <TextInput source="vorname" />
      <TextInput source="name" />
      <ReferenceInput label="Sektion" source="id_section" reference="section" sort={{ field: 'section_name', order: 'ASC' }} perPage={100} validate={required()}>
        <SelectInput optionText="section_name" />
      </ReferenceInput>
      <ReferenceInput label="Benutzergruppe" source="id_usergroup" reference="usergroup" sort={{ field: 'usergroup_name', order: 'ASC' }} perPage={10} validate={required()}>
        <SelectInput optionText="usergroup_name" />
      </ReferenceInput>
      <TextField source="tour_anz" label="Anzahl Touren" readonly/>
      <TextField source="last_tour" label="Letzte Tour" readonly/>
      <TextField source="last_connect" label="Letztes Login" readonly/>
    </SimpleForm>
  );
};

export default {
  
  options: { label: "Benutzer" },
  
  list: (props) => (
    <ListForm {...props} title="Benutzer" />
  ),
  
  create: (props) => (
    <Create {...props} title="Benutzer hinzufügen">
      <CEForm {...props} />
    </Create>
  ),
  
  edit: (props) => (
    <Edit title="Benutzer bearbeiten" undoable={false} {...props}>
      <CEForm {...props} />
    </Edit>
  )
}   
